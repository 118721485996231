<template>
  <article>
    <div
      v-show="showDetail"
      id="popupMedicalView"
      class="layerCommon medical-view-type"
      style="display: block"
      @click.self="closeMedicalModel"
    >
      <div
        class="layer-medical-detail"
        @click.self="closeMedicalModel"
      >
        <div class="medical-detail-wrap">
          <div class="layer-utility">
            <button
              class="btn-close"
              @click="closeMedicalModel"
            >
              close
            </button>
            <button
              v-if="medicalTrend.isComment"
              class="btn-reply"
              @click="scrollToElement"
            >
              reply
            </button>
            <button
              class="btn-scrap"
              :class="{ 'active': isScrap }"
              @click="toggleScrap"
            >
              scrap
            </button>
            <button
              class="btn-next"
              @click="moveNext(liveInfoId)"
            >
              next
            </button>
            <button
              class="btn-prev"
              @click="movePrev(liveInfoId)"
            >
              prev
            </button>
          </div>
          <div class="md-header">
            <dl>
              <dt>{{ medicalTrend.liveInfo.title }}</dt>
              <dd>{{ medicalTrend.liveInfo.startDate | $dateFormatter('YYYY-MM-DD')  }} {{ medicalTrend.liveInfo.startDate ? `(${getDateString(medicalTrend.liveInfo.startDate)})` : '' }}</dd>
            </dl>
            <div class="text-info">
              <span class="viewer">{{ medicalTrend.liveInfo.viewCount }}</span>
              <span class="reply" v-if="medicalTrend.isComment">{{ commentCount }}</span>
              <span class="scrap">{{ scrapCount }}</span>
            </div>
          </div>
          <div
            class="md-contents"
          >
            <!-- html 영역 -->
            <div
              v-dompurify-html="medicalTrend.liveInfo.htmlContent"
              class="content"
              :style="{ maxWidth: '100%', width: 'auto', marginBottom: '30px' }"
              @click.stop="handleClick"
            />
          </div>

          <component
            v-for="(event, index) in progressEvent"
            :key="index"
            :is="event.component"
            :event-id="event.eventId"
            :notice-id="event.id"
            :content-id="liveInfoId"
          />
          <comment-form
            v-show="medicalTrend.isComment"
            ref="commentForm"
            :content-type="2"
            :comment-survey-id="medicalTrend.commentSurveyId ? medicalTrend.commentSurveyId : -1"
          />
        </div>
      </div>
    </div>
    <alert-dialog :options="alertProps" />
    <confirm-dialog :options="confirmProps" />
  </article>
</template>
<script>
import axios from '@axios'
import { getUserData, isUserLoggedIn } from '@/auth/utils'
import { callExternalApi } from '@/core/global/global-liveinfo'
import CommentForm from '../CommentForm.vue'
import dayjs from 'dayjs'

export default {
  components: {
    CommentForm,
  },
  props: {
    liveInfoIds: Array,
  },
  data() {
    return {
      liveInfoId: null,
      medicalTrend: {
        liveInfo: [],
      },
      showDetail: false,
      commentCount: 0,
      scrapCount: 0,
      scrap:
      {
        id: 0,
        userId: 0,
        liveInfoId: 0,
        medicalTrend: {},
      },
      user: {},
      isScrap: false,
      commentlist: [],
      comment: {
        commentId: 0,
        commentText: '',
        contentType: 2,
        contentId: 0,
        userId: 0,
        isEditing: false,
        editText: '',
        isReplyEditing: false,
        parentId: 0,
        replyEditText: '',
        isReplyModify: false,
        replyModifyText: '',
        isUserDelete: false,
      },
      commentId: null,
      progressEvent: null,
    }
  },
  mounted() {
    this.user = getUserData()
    this.contentType = 2 // 메디컬 트랜드
  },
  methods: {
    fetchMedical(id) {
      axios.get(`/fu/medical/${id}`)
        .then(rs => {

          this.medicalTrend = rs.data.item
          this.commentCount = rs.data.commentCount
          this.scrapCount = rs.data.scrapCount
          this.isScrap = rs.data.isScrap
          this.scrap = rs.data.scrap
          this.showDetail = true
          if (this.medicalTrend.isComment) this.$refs.commentForm.fetchComment(this.liveInfoId, this.contentType)
          
          setTimeout(() => {
            this.scrollTop();
          }, 0);

          this.fetchMedicalEvent()
        })
        .catch(() => {
          this.showAlertDialog('조회에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
        })
    },
    fetchMedicalEvent() {
      axios.get(`/fu/event/progress/3`)
        .then(rs => {
          this.progressEvent = rs.data

          if (this.progressEvent) {
            this.progressEvent = this.progressEvent.filter(e => { return dayjs(this.medicalTrend.liveInfo.startDate).diff(e.startDate, 'day') >= 0 }).map(x => ({ 
              id: x.id,
              eventId: x.eventId,
              eventTypeId: x.eventTypeId,
              eventPlace: x.eventPlace,
              component: () => import(`@/components/event/${x.component}`)
            }))
          }
        })
        .catch(() => {
        })
    },
    openMedicalModal(id, innerCall = false) {
      this.liveInfoId = id
      this.isScrap = false

      this.fetchMedical(id)
      this.updateViewCount(id)
      if (innerCall) this.$emit('statUpdate', id)

      this.comment.commentText = ''
      this.comment.replyEditText = ''
      this.comment.isEditing = false
      this.comment.isReplyEditing = false
    },
    updateViewCount(id) {
      axios.patch(`/fu/medical/UpdateViewCount/${id}`).catch(() => {})
    },
    closeMedicalModel() {
      this.showDetail = false
      this.$emit('fetchMedicals')
    },
    getDateString(dateStr) {
      const daysOfWeek = ['일', '월', '화', '수', '목', '금', '토']
      const date = new Date(dateStr)
      const dayOfWeek = daysOfWeek[date.getDay()]

      return `${dayOfWeek}`
    },
    scrollTop() {
      
      const detailWrap = document.querySelectorAll(".medical-detail-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: 0, behavior: 'instant' })
      })
    },
    scrollToElement() {
      const location = document.querySelector(".md-reply").offsetTop
      const value = location - 144
      const detailWrap = document.querySelectorAll(".medical-detail-wrap")

      detailWrap.forEach(item => {
        item.scrollTo({ top: value, behavior: 'smooth' })
      })
    },
    async checkedLogin() {
      const isLoggedIn = await isUserLoggedIn()

      if (!isLoggedIn) {
        this.$router.push({
          name: 'login-main',
          query: { redirect: this.$route.name },
        })
      }
    },
    async toggleScrap() {
      this.checkedLogin()

      const btnScrap = document.querySelector('.layer-utility .btn-scrap')

      if (btnScrap.classList.contains('disabled')) {
        return
      }

      this.scrap.liveInfoId = this.liveInfoId
      this.scrap.userId = this.user.id
      this.scrap.medicalTrend = null

      try {
        btnScrap.classList.add('disabled') // 요청 시작전 disabled

        const rs = await axios.put("/fu/medical/toggleScrap", this.scrap)
        const { id } = rs.data

        this.scrap.id = id
        this.isScrap = id !== 0
      } catch {
        this.showAlertDialog('스크랩에 실패하였습니다.\n잠시 후 다시 시도해 주세요.')
      } finally {
        btnScrap.classList.remove('disabled') // 요청 완료 후 disabled 삭제
      }
    },
    getNextId(currentId) {
      const currentIndex = this.liveInfoIds.indexOf(currentId)
      const nextIndex = (currentIndex + 1) % this.liveInfoIds.length

      return this.liveInfoIds[nextIndex]
    },
    getPrevId(currentId) {
      const currentIndex = this.liveInfoIds.indexOf(currentId)
      const prevIndex = (currentIndex - 1 + this.liveInfoIds.length) % this.liveInfoIds.length

      return this.liveInfoIds[prevIndex]
    },
    moveNext() {
      const nextId = this.getNextId(this.liveInfoId)
      this.openMedicalModal(nextId, true)
    },
    movePrev() {
      const prevId = this.getPrevId(this.liveInfoId)
      this.openMedicalModal(prevId, true)
    },
    handleClick(event) {
      if (event.target.localName === 'a') {
        const href = event.target.attributes.href.value
        callExternalApi("LinkUrl", href)

        event.preventDefault()
      } else if (event.target.parentElement.localName === 'a') {
        const href = event.target.parentElement.attributes.href.value
        callExternalApi("LinkUrl", href)
        event.preventDefault()
      }
    },
  },
}
</script>
