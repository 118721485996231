<!-- eslint-disable vue/require-v-for-key -->
<template>
  <article>
    <div>
      <alert-dialog :options="alertProps" />
      <confirm-dialog :options="confirmProps" />

      <div class="md-reaction-wrap">
        <p class="title">
          콘텐츠에 대한 의견을 남겨 주세요
        </p>
        <p class="title-sub">
          선생님의 소중한 의견은 콘텐츠 제작에 큰 힘이 됩니다
        </p>
        <ul class="buttons-wrap">
          <li>
            <button
              :class="`function-01 ${((join && userName) || !join) && feedbackData.feedback01 ? ' active' : ''}`"
              @click="actionFeedback('feedback01')"
            >
              <i />
              <p class="title">
                강력추천
              </p>
              <span class="amount">{{ feedbackData.countFeedback01 | $numberFormatter }}</span>
            </button>
          </li>
          <li>
            <button
              :class="`function-02 ${((join && userName) || !join) && feedbackData.feedback02 ? ' active' : ''}`"
              @click="actionFeedback('feedback02')"
            >
              <i />
              <p class="title">
                도움백배
              </p>
              <span class="amount">{{ feedbackData.countFeedback02 | $numberFormatter }}</span>
            </button>
          </li>
          <li>
            <button
              :class="`function-03 ${((join && userName) || !join) && feedbackData.feedback03 ? ' active' : ''}`"
              @click="actionFeedback('feedback03')"
            >
              <i />
              <p class="title">
                눈이번쩍
              </p>
              <span class="amount">{{ feedbackData.countFeedback03 | $numberFormatter }}</span>
            </button>
          </li>
          <li>
            <button
              :class="`function-04 ${((join && userName) || !join) && feedbackData.feedback04 ? ' active' : ''}`"
              @click="actionFeedback('feedback04')"
            >
              <i />
              <p class="title">
                후속강추
              </p>
              <span class="amount">{{ feedbackData.countFeedback04 | $numberFormatter }}</span>
            </button>
          </li>
        </ul>
      </div>
    </div>
  </article>
</template>

<script>
import axios from '@axios'
import { mapGetters } from 'vuex'
import { isUserLoggedIn } from '@/auth/utils'
import { getUserLoginInfo, } from '@/common/userInfo/service'

export default {
  props: {
    contentType: {
      type: Number,
      required: true,
    },
    isHook: {
      type: Boolean,
      default: false,
    },
    memberOnly: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isProcessing: false,
      feedbackData: {},
    }
  },

  computed: {
    ...mapGetters({
      join: 'util/getJoin', // 회원여부
      userName: 'account/getName',
      // userName: 'infoData/getUserName',
      medicalDept: 'infoData/getMedicalDept',
      shaEnLicenseNum: 'infoData/getShaEnLicenseNum',
    })
  },

  watch: {
    shaEnLicenseNum() {
      if (this.join === null) getUserLoginInfo()
    },
  },

  created() {
    // 회원여부 조회
    if (this.join === null) getUserLoginInfo()
  },

  mounted() {
  },

  methods: {
    async checkedLogin() {
      let isLoggedIn = false

      if (this.memberOnly || (this.memberOnly === false && this.join)) {
        // 회원만 댓글 허용(default) 또는 비회원 허용이면서 회원인 경우에는 로그인 필수
        isLoggedIn = await isUserLoggedIn()
      } else {
        // 비회원 허용이면서 비회원인 경우
        isLoggedIn = true
      }

      if (!isLoggedIn) {
        this.$router.push({
          name: 'login-main',
          query: { redirect: this.$route.name },
        })
      }
    },
    fetchFeedback(id, contentType) {
      axios.get(`/fu/medical/feedback/${id}`, {
        params: {
          contentType,
          userHash: this.shaEnLicenseNum
        },
      })
        .then((rs) => {
          this.feedbackData = rs.data
        })
        .catch(() => {})
    },
    actionFeedback(feedback) {
      if (this.isProcessing) {
        return
      }

      if (!(this.join && this.userName) || !this.join) {
        this.checkedLogin()
      } else {
        this.isProcessing = true

        const currentActive = this.feedbackData.feedback01 || this.feedbackData.feedback02 || this.feedbackData.feedback03 || this.feedbackData.feedback04

        if (currentActive) {
        // 현재 활성화중인 값이 있다면
          if (this.feedbackData[feedback]) {
          // 현재 활성화 중인 값과 클릭한 값이 같다면 => 비활성화
            this.feedbackData[feedback] = false
          } else {
          // 다르다면 => 이전 값 비활성화 + 클릭한 값 활성화
            this.feedbackData.feedback01 = false
            this.feedbackData.feedback02 = false
            this.feedbackData.feedback03 = false
            this.feedbackData.feedback04 = false
            this.feedbackData[feedback] = true
          }
        } else {
          // 현재 활성화중인 값이 없다면 => 클릭한 값 활성화
          this.feedbackData[feedback] = true
        }

        // 현재 입력 저장
        axios.post(`/fu/medical/feedback`, {
          contentType: this.feedbackData.contentType,
          contentId: this.feedbackData.contentId,
          tid: this.feedbackData.tid,
          feedback01: this.feedbackData.feedback01,
          feedback02: this.feedbackData.feedback02,
          feedback03: this.feedbackData.feedback03,
          feedback04: this.feedbackData.feedback04,
        })
          .then(() => {
          // fetchData
            this.fetchFeedback(this.feedbackData.contentId, this.feedbackData.contentType)

            this.isProcessing = false
          })
          .catch(() => {
            this.isProcessing = false
          })
      }
    },
  },
}
</script>
