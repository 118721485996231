var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "article",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showDetail,
              expression: "showDetail",
            },
          ],
          staticClass: "layerCommon medical-view-type",
          staticStyle: { display: "block" },
          attrs: { id: "popupMedicalView" },
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) {
                return null
              }
              return _vm.closeMedicalModel($event)
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "layer-medical-detail",
              on: {
                click: function ($event) {
                  if ($event.target !== $event.currentTarget) {
                    return null
                  }
                  return _vm.closeMedicalModel($event)
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "medical-detail-wrap" },
                [
                  _c("div", { staticClass: "layer-utility" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-close",
                        on: { click: _vm.closeMedicalModel },
                      },
                      [_vm._v(" close ")]
                    ),
                    _vm.medicalTrend.isComment
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-reply",
                            on: { click: _vm.scrollToElement },
                          },
                          [_vm._v(" reply ")]
                        )
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn-scrap",
                        class: { active: _vm.isScrap },
                        on: { click: _vm.toggleScrap },
                      },
                      [_vm._v(" scrap ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn-next",
                        on: {
                          click: function ($event) {
                            return _vm.moveNext(_vm.liveInfoId)
                          },
                        },
                      },
                      [_vm._v(" next ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "btn-prev",
                        on: {
                          click: function ($event) {
                            return _vm.movePrev(_vm.liveInfoId)
                          },
                        },
                      },
                      [_vm._v(" prev ")]
                    ),
                  ]),
                  _c("div", { staticClass: "md-header" }, [
                    _c("dl", [
                      _c("dt", [
                        _vm._v(_vm._s(_vm.medicalTrend.liveInfo.title)),
                      ]),
                      _c("dd", [
                        _vm._v(
                          _vm._s(
                            _vm._f("$dateFormatter")(
                              _vm.medicalTrend.liveInfo.startDate,
                              "YYYY-MM-DD"
                            )
                          ) +
                            " " +
                            _vm._s(
                              _vm.medicalTrend.liveInfo.startDate
                                ? "(" +
                                    _vm.getDateString(
                                      _vm.medicalTrend.liveInfo.startDate
                                    ) +
                                    ")"
                                : ""
                            )
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "text-info" }, [
                      _c("span", { staticClass: "viewer" }, [
                        _vm._v(_vm._s(_vm.medicalTrend.liveInfo.viewCount)),
                      ]),
                      _vm.medicalTrend.isComment
                        ? _c("span", { staticClass: "reply" }, [
                            _vm._v(_vm._s(_vm.commentCount)),
                          ])
                        : _vm._e(),
                      _c("span", { staticClass: "scrap" }, [
                        _vm._v(_vm._s(_vm.scrapCount)),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "md-contents" }, [
                    _c("div", {
                      directives: [
                        {
                          name: "dompurify-html",
                          rawName: "v-dompurify-html",
                          value: _vm.medicalTrend.liveInfo.htmlContent,
                          expression: "medicalTrend.liveInfo.htmlContent",
                        },
                      ],
                      staticClass: "content",
                      style: {
                        maxWidth: "100%",
                        width: "auto",
                        marginBottom: "30px",
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.handleClick($event)
                        },
                      },
                    }),
                  ]),
                  _vm._l(_vm.progressEvent, function (event, index) {
                    return _c(event.component, {
                      key: index,
                      tag: "component",
                      attrs: {
                        "event-id": event.eventId,
                        "notice-id": event.id,
                        "content-id": _vm.liveInfoId,
                      },
                    })
                  }),
                  _c("comment-form", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.medicalTrend.isComment,
                        expression: "medicalTrend.isComment",
                      },
                    ],
                    ref: "commentForm",
                    attrs: {
                      "content-type": 2,
                      "comment-survey-id": _vm.medicalTrend.commentSurveyId
                        ? _vm.medicalTrend.commentSurveyId
                        : -1,
                    },
                  }),
                ],
                2
              ),
            ]
          ),
        ]
      ),
      _c("alert-dialog", { attrs: { options: _vm.alertProps } }),
      _c("confirm-dialog", { attrs: { options: _vm.confirmProps } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }